import React, { forwardRef } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarSelector } from '../../store/snackbar/selectors';
import { setSnackbar } from '../../store/snackbar/actions';

const SnackbarComponent = () => {
  const dispatch = useDispatch();

  const Alert = forwardRef(function Alert(props, ref) {
    return (
      <MuiAlert
        elevation={6}
        ref={ref}
        variant={snackbar.variant || 'filled'}
        {...props}
      />
    );
  });

  const snackbar = useSelector(snackbarSelector());

  const handleClose = () => {
    dispatch(setSnackbar({ open: false }));
  };

  return (
    <Snackbar
      open={snackbar.open}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert onClose={handleClose} severity={snackbar.severity || 'success'}>
        {snackbar.message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarComponent;
